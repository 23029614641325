import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const CustomerOverview = () => {
  const { allCustomersJson } = useStaticQuery(graphql`
    query CustomersQuery {
      allCustomersJson {
        edges {
          node {
            id
            name
            logoUrl
            link
          }
        }
      }
    }
  `);
  const { edges: customers } = allCustomersJson;

  return (
    <div className="container customer-overview">
      <div className="row justify-content-around">
        {customers &&
          customers.map(({ node }) => (
            <div
              className="col col-6 col-md-4 col-lg-2 customer-wrapper"
              key={node.id}
            >
              <div className="row">
                <div className="col">
                  <div className="row">
                    <div className="col">
                      <div className="row justify-content-center">
                        <a
                          href={node.link || '#'}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {node.logoUrl ? (
                            <img
                              src={node.logoUrl}
                              alt={`${node.name}`}
                              className="customer-logo"
                            />
                          ) : (
                            <p>{node.name}</p>
                          )}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CustomerOverview;
